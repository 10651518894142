






import { Component, Vue } from "vue-property-decorator";
import ContactForm from "@/general/ContactForm.vue";
import {Module} from "@/api";
import NotanLayout from "@/notan/NotanLayout.vue";

@Component({
  components: {
    NotanLayout,
    ContactForm
  },
})
export default class NotanContact extends Vue {

  readonly module = Module.NOTAN;
}

