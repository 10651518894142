



























import {Component, Prop, Vue} from 'vue-property-decorator';
import GenericHeader from "@/general/GenericHeader.vue";
import GenericFooter from "@/general/GenericFooter.vue";


@Component({
  components: {
    GenericFooter,
    GenericHeader
  },
})
export default class GeoWarLayout extends Vue {

  @Prop({required: true})
  readonly title!: string;

  get hasHeader() {
    return this.$slots.header;
  }
}
