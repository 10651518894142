



























































import {Component, Vue} from 'vue-property-decorator';
import SectionContainer from "@/home/components/SectionContainer.vue";

enum ItemIcon {
  EDUCATION = 'mdi-school',
  WORK = 'mdi-briefcase'
}

type TimeLineItem = {
  title: string;
  date: string;
  icon: ItemIcon;
  education?: string;
  company?: string;
  location?: string;
  content?: string;
}

@Component({
  components: {
    SectionContainer
  },
})
export default class SectionAboutMe extends Vue {

  items: Array<TimeLineItem> = [
    {
      title: 'Abitur',
      date: '2009 - 2017',
      icon: ItemIcon.EDUCATION,
      education: 'Gymnasium Stephaneum',
      location: 'Aschersleben',
      content: 'In der Schule habe ich bereits erste Programmiererfahrungen gesammelt und dieses Wissen bei mehreren Jugend forscht Wettbewerben unter Beweis gestellt. Zusätzlich habe ich im Rahmen einer besonderen Lernleistung die Webplattform der Schule von Grund auf neu implementiert.'
    },
    {
      title: 'Bachelor Informatik',
      date: '2017 - 2021',
      icon: ItemIcon.EDUCATION,
      education: 'Otto-von-Guericke-Universität',
      location: 'Magdeburg',
      content: 'Ich habe Module in Bereichen Software Engineering, Machine Learning und IT Security belegt. Da das Studium teilweise recht theoretisch war, habe ich zusätzlich mit Freunden an Hobby-Projekten gearbeitet. Zwei Software-Lösungen "FinVote" und "Welcome to OVGU" sind sogar aktiv im Uni-Einsatz.'
    },
    {
      title: 'Job: App-Entwickler',
      date: '2021 - 2023',
      icon: ItemIcon.WORK,
      company: 'source.kitchen GmbH',
      location: 'Leipzig',
      content: 'Als App-Entwickler habe ich mit Flutter an der Entwicklung mobiler Apps gearbeitet. Kunden waren in erster Linie Immobilienunternehmen, die ihre Prozesse digitalisieren wollen.',
    },
    {
      title: 'Selbstständig',
      date: '2023 - heute',
      icon: ItemIcon.WORK,
      location: 'Berlin',
      content: 'Als Selbstständiger bin ich teils bei Freelancer-Projekten involviert, teils arbeite ich an eigenen Projekten.',
    }
  ];
}
