















import { Component, Vue } from "vue-property-decorator";
import NotanLayout from "@/notan/NotanLayout.vue";

@Component({
  components: {
    NotanLayout
  },
})
export default class NotanPrivacy extends Vue {}
