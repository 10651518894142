var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticClass:"grey darken-3 pt-8 white--text",staticStyle:{"flex-direction":"column","align-items":"center","justify-content":"start","min-height":"300px"},attrs:{"padless":""}},[_c('span',[_vm._v("© "+_vm._s(_vm.currYear)+" Tien Do Nam")]),_c('div',[_c('router-link',{attrs:{"to":_vm.privacyUrl},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{class:_vm.linkClass,staticStyle:{"text-decoration":"none"},attrs:{"href":href},on:{"click":navigate}},[_vm._v("Datenschutz")])]}}])}),_c('span',{staticStyle:{"color":"white"}},[_vm._v(" | ")]),_c('router-link',{attrs:{"to":_vm.contactUrl},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{class:_vm.linkClass,staticStyle:{"text-decoration":"none"},attrs:{"href":href},on:{"click":navigate}},[_vm._v("Kontakt")])]}}])}),_c('span',{staticStyle:{"color":"white"}},[_vm._v(" | ")]),_c('router-link',{attrs:{"to":_vm.imprintUrl},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{class:_vm.linkClass,staticStyle:{"text-decoration":"none"},attrs:{"href":href},on:{"click":navigate}},[_vm._v("Impressum")])]}}])})],1),_c('div',{staticClass:"pt-12"},[_vm._v(" powered by "),_c('a',{class:_vm.linkClass,staticStyle:{"text-decoration":"none"},attrs:{"href":"https://vuejs.org/","target":"_blank"}},[_vm._v("Vue")]),_c('span',[_vm._v(", ")]),_c('a',{class:_vm.linkClass,staticStyle:{"text-decoration":"none"},attrs:{"href":"https://vuetifyjs.com/","target":"_blank"}},[_vm._v("Vuetify")]),_c('span',[_vm._v(" and ")]),_c('a',{class:_vm.linkClass,staticStyle:{"text-decoration":"none"},attrs:{"href":"https://www.typescriptlang.org/","target":"_blank"}},[_vm._v("TypeScript")])])])}
var staticRenderFns = []

export { render, staticRenderFns }