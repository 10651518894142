const API_URL = '/api';

interface RequestParameter {
    method: 'GET' | 'POST';
    route: string;
    body?: object;
    params?: { [key: string]: any };
}

async function makeRequest({ method, route, body, params }: RequestParameter): Promise<any> {
    const url = new URL(API_URL+'/'+route, document.location.href);

    if (params) {
        // add query params
        for (const propName in params) {
            if (params[propName] === null || params[propName] === undefined) {
                delete params[propName];
            }
        }

        url.search = new URLSearchParams(params).toString();
    }

    const response = await fetch(url.toString(), {
        method,
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
    });

    // handle errors
    if(response.status >= 400) {
        throw response.status;
    }

    // return the result
    return JSON.parse((await response.text()) || "{}");
}

// string values are used for API
export enum Module {
    HOMEPAGE = "HOMEPAGE",
    GEOWAR = "GEOWAR",
    NOTAN = "NOTAN"
}

interface ContactRequest {
    captcha: string;
    module: Module;
    name: string;
    email: string;
    phone: string;
    content: string;
}

export async function sendContactRequest(request: ContactRequest) {
    return await makeRequest({
        route: 'contact',
        method: 'POST',
        body: request
    });
}