























import {Component, Prop, Vue} from 'vue-property-decorator';
import SectionContainer from "@/home/components/SectionContainer.vue";

interface ContactItem {
  icon: string;
  text: string;
  href: string;
}

@Component({
  components: {
    SectionContainer
  },
})
export default class SectionContact extends Vue {

  @Prop({required: true})
  readonly mobile!: boolean;

  items: Array<ContactItem> = [
    {
      icon: 'mdi-email',
      text: 'dev.tien.donam@gmail.com',
      href: 'mailto:dev.tien.donam@gmail.com'
    },
    {
      icon: 'mdi-linkedin',
      text: 'linkedin.com/in/tien-do-nam',
      href: 'https://linkedin.com/in/tien-do-nam'
    },
    {
      icon: 'mdi-github-circle',
      text: 'github.com/Tienisto',
      href: 'https://github.com/Tienisto'
    }
  ];
}
