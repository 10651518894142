






import { Component, Vue } from "vue-property-decorator";
import Imprint from "@/general/Imprint.vue";
import HomeLayout from "@/home/HomeLayout.vue";

@Component({
  components: {
    HomeLayout,
    Imprint,
  },
})
export default class HomeImprint extends Vue {}
