


















































import {Component, Prop, Vue} from 'vue-property-decorator';
import SectionContainer from "@/home/components/SectionContainer.vue";

interface PortfolioGroup {
  icon: string;
  title: string;
  items: Array<string>;
}

interface FavoriteStack {
  title: string;
  content: string;
}

@Component({
  components: {
    SectionContainer
  },
})
export default class SectionPortfolio extends Vue {

  @Prop({required: true})
  readonly mobile!: boolean;

  groups: Array<PortfolioGroup> = [
    {
      icon: 'mdi-cellphone',
      title: 'App',
      items: [
          'Flutter',
          'LibGDX (für Spiele)'
      ]
    },
    {
      icon: 'mdi-laptop',
      title: 'Frontend',
      items: [
        'Vue (JS / TS)',
        'React'
      ]
    },
    {
      icon: 'mdi-server',
      title: 'Backend',
      items: [
        'Spring (Java / Kotlin)',
        'Axum (Rust)',
      ]
    },
    {
      icon: 'mdi-database',
      title: 'Datenbank',
      items: [
        'PostgreSQL',
        'MySQL / MariaDB',
        'SQLite',
      ]
    },
    {
      icon: 'mdi-wrench',
      title: 'Sonstiges',
      items: [
        'REST',
        'Docker',
        'Git',
      ]
    }
  ];

  favoriteStack: Array<FavoriteStack> = [
    {
      'title': 'Flutter (Dart)',
      'content': 'Für Apps verwende ich gerne Flutter. Aufgrund der eigenen Rendering-Engine sieht die UI auf jedem Gerät gleich aus, so dass das lästige Debugging auf unterschiedlichen Geräten kein Thema ist.'
    },
    {
      'title': 'Vue (TypeScript)',
      'content': 'Vue besitzt viele nützliche Funktionen für das Templating, weswegen weniger Code erforderlich ist.  Zudem finde ich die Struktur template-script-style sehr schön. Natürlich darf statische Typisierung mit TypeScript nicht fehlen!'
    },
    {
      'title': 'Spring (Kotlin)',
      'content': 'Bei der Backend-Entwicklung bevorzuge ich Spring, aufgrund der Reife und Stabilität des Frameworks. Mit Kotlin gehören zudem NPEs der Vergangenheit an.'
    }
  ];
}
