































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class GenericFooter extends Vue {

  @Prop({required: true})
  readonly privacyUrl!: string;

  @Prop({required: true})
  readonly contactUrl!: string;

  @Prop({required: true})
  readonly imprintUrl!: string;

  @Prop({required: true})
  readonly linkClass!: string;

  get currYear(): number {
    return new Date().getFullYear();
  }
}
