


















import {Component, Prop, Vue} from 'vue-property-decorator';
import GenericHeader from "@/general/GenericHeader.vue";
import HomeFooter from "@/home/components/HomeFooter.vue";

@Component({
  components: {
    HomeFooter,
    GenericHeader
  },
})
export default class HomeLayout extends Vue {

  @Prop({required: true})
  readonly title!: string;
}
