












































































import {Component, Vue, Ref} from 'vue-property-decorator';
import HomeFooter from "@/home/components/HomeFooter.vue";
import SectionContact from "@/home/components/SectionContact.vue";
import SectionProjects from "@/home/components/SectionProjects.vue";
import SectionPortfolio from "@/home/components/SectionPortfolio.vue";
import SectionAboutMe from "@/home/components/SectionAboutMe.vue";
import AnimatedText from "@/home/components/AnimatedText.vue";

enum Section {
  NONE = -1,
  ABOUT_ME = 0,
  PORTFOLIO = 1,
  PROJECTS = 2,
  CONTACT = 3
}

@Component({
  components: {
    AnimatedText,
    SectionAboutMe,
    SectionPortfolio,
    SectionProjects,
    SectionContact,
    HomeFooter
  },
})
export default class Home extends Vue {

  @Ref('homeNav') readonly homeNav!: HTMLElement

  // used for check in viewport
  @Ref('sectionPortfolio') readonly sectionPortfolio!: Vue
  @Ref('sectionProjects') readonly sectionProjects!: Vue
  @Ref('sectionContact') readonly sectionContact!: Vue

  // used for scrolling
  @Ref('sectionAboutMePre') readonly sectionAboutMePre!: HTMLElement
  @Ref('sectionPortfolioPre') readonly sectionPortfolioPre!: HTMLElement
  @Ref('sectionProjectsPre') readonly sectionProjectsPre!: HTMLElement
  @Ref('sectionContactPre') readonly sectionContactPre!: HTMLElement


  mobile = false;
  navStartY = 0;
  section = Section.NONE;
  desktopLandingVisible = true;

  repeatIntro() {
    this.desktopLandingVisible = false;
    this.$nextTick(() => {
      this.desktopLandingVisible = true;
    });
  }

  scrollTo(index: number) {
    const scrollBehavior: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'start'
    };

    switch (index) {
      case Section.ABOUT_ME:
        this.sectionAboutMePre.scrollIntoView(scrollBehavior);
        break;
      case Section.PORTFOLIO:
        this.sectionPortfolioPre.scrollIntoView(scrollBehavior);
        break;
      case Section.PROJECTS:
        this.sectionProjectsPre.scrollIntoView(scrollBehavior);
        break;
      case Section.CONTACT:
        this.sectionContactPre.scrollIntoView(scrollBehavior);
        break;
    }
  }

  scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  onResize() {
    this.mobile = this.$vuetify.breakpoint.smAndDown;
  }

  isHighlighted(index: number): boolean {
    return this.section === index;
  }

  scrollHandler() {
    if (window.pageYOffset > this.navStartY) {
      this.homeNav.classList.add('home-nav-sticky');
      this.homeNav.style.animation = 'none'
      this.homeNav.style.opacity = '1';
      this.homeNav.classList.add('elevation-3');
      this.section = Section.ABOUT_ME;
    } else {
      this.homeNav.classList.remove('home-nav-sticky');
      this.homeNav.classList.remove('elevation-3');
      this.section = Section.NONE;
    }

    if (this.isViewed(this.sectionContact.$el)) {
      this.section = Section.CONTACT;
    } else if (this.isViewed(this.sectionProjects.$el)) {
      this.section = Section.PROJECTS;
    } else if (this.isViewed(this.sectionPortfolio.$el)) {
      this.section = Section.PORTFOLIO;
    }
  }

  isViewed(el: Element) {
    const rect = el.getBoundingClientRect();
    return rect.top < this.vh(0.4);
  }

  vh(percent: number) {
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return percent * h;
  }

  mounted() {
    this.onResize();
    this.navStartY = this.homeNav.offsetTop + 5;
    window.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('resize', this.onResize, { passive: true });
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }

  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  }
}
