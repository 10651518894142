






import { Component, Vue } from "vue-property-decorator";
import ContactForm from "@/general/ContactForm.vue";
import {Module} from "@/api";
import HomeLayout from "@/home/HomeLayout.vue";

@Component({
  components: {
    HomeLayout,
    ContactForm,
  },
})
export default class HomeContact extends Vue {

  readonly module = Module.HOMEPAGE;
}

