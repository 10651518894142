import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Notan from "@/notan/views/Notan.vue";
import NotanPrivacy from "@/notan/views/NotanPrivacy.vue";
import NotanContact from "@/notan/views/NotanContact.vue";
import NotanImprint from "@/notan/views/NotanImprint.vue";
import Home from "@/home/views/Home.vue";
import HomePrivacy from "@/home/views/HomePrivacy.vue";
import HomeContact from "@/home/views/HomeContact.vue";
import HomeImprint from "@/home/views/HomeImprint.vue";
import Special from "@/home/views/Special.vue";
import GeoWar from "@/geowar/views/GeoWar.vue";
import GeoWarPrivacy from "@/geowar/views/GeoWarPrivacy.vue";
import GeoWarContact from "@/geowar/views/GeoWarContact.vue";
import GeoWarImprint from "@/geowar/views/GeoWarImprint.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/privacy',
    name: 'HomePrivacy',
    component: HomePrivacy
  },
  {
    path: '/contact',
    name: 'HomeContact',
    component: HomeContact
  },
  {
    path: '/imprint',
    name: 'HomeImprint',
    component: HomeImprint
  },
  {
    path: '/notan',
    name: 'Notan',
    component: Notan
  },
  {
    path: '/notan/privacy',
    name: 'NotanPrivacy',
    component: NotanPrivacy
  },
  {
    path: '/notan/contact',
    name: 'NotanContact',
    component: NotanContact
  },
  {
    path: '/notan/imprint',
    name: 'NotanImprint',
    component: NotanImprint
  },
  {
    path: '/geowar',
    name: 'GeoWar',
    component: GeoWar
  },
  {
    path: '/geowar/privacy',
    name: 'GeoWarPrivacy',
    component: GeoWarPrivacy
  },
  {
    path: '/geowar/contact',
    name: 'GeoWarContact',
    component: GeoWarContact
  },
  {
    path: '/geowar/imprint',
    name: 'GeoWarImprint',
    component: GeoWarImprint
  },
  {
    path: '/'+encodeURIComponent('𓂺'),
    name: 'Special',
    component: Special
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
})

export default router
