


























































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import HomeLayout from "@/home/HomeLayout.vue";

@Component({
  components: {
    HomeLayout
  },
})
export default class HomePrivacy extends Vue {}
