








import { Component, Vue } from 'vue-property-decorator';
import {ViewTransitionType} from "@/general/view-transition-type";

@Component
export default class GenericHeader extends Vue {

  toHome(e: Event) {
    e.preventDefault();
    this.$emit('view-transition', ViewTransitionType.HOME);
    setTimeout(() => {
      this.$router.push('/');
    }, 600);
  }
}
