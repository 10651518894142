




import {Component, Vue, Prop, Ref} from 'vue-property-decorator';

@Component
export default class AnimatedText extends Vue {

  @Prop({required: true})
  readonly text!: Array<string>;

  @Ref('span') readonly span!: HTMLElement

  curr = '|';
  timerId: number | null = null;

  mounted() {
    let wordIndex = 0;
    let forward = true;
    let letterIndex = 0;
    let waitIndex = 0;

    this.timerId = setTimeout(() =>{
      this.timerId = setInterval(() => {
        if(waitIndex) {
          const showCursor = (Math.floor(waitIndex/10) % 2) === 0;
          if(showCursor)
            this.span.innerText = this.text[wordIndex] + '|';
          else
            this.span.innerText = this.text[wordIndex];
          waitIndex--;
          return;
        }

        if (letterIndex === 0) {
          this.span.innerHTML = '|';
        } else {
          this.span.innerHTML = this.text[wordIndex].substr(0, letterIndex) + '|';
        }

        if (forward) {
          letterIndex++;
          if (letterIndex === this.text[wordIndex].length) {
            waitIndex = 60;
            forward = false;
          }
        } else {
          letterIndex--;
          if (letterIndex === 0) {
            forward = true;
            wordIndex = (wordIndex + 1) % this.text.length;
          }
        }
      }, 50);
    }, 2000);
  }

  destroyed() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

}
