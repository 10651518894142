


































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SectionContainer extends Vue {

  @Prop({required: true})
  readonly title!: string;

  get hasIntro() {
    return this.$slots.intro;
  }

}
