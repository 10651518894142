



































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Library, libraryList, OpenType, Product, productList} from '@/home/components/product-list';
import SectionContainer from "@/home/components/SectionContainer.vue";

@Component({
  components: {
    SectionContainer
  },
})
export default class SectionProjects extends Vue {

  @Prop({required: true})
  readonly mobile!: boolean;

  products: Array<Product> = productList;
  libraries: Array<Library> = libraryList;

  getOpenString(product: Product): string {
    switch (product.open) {
      case OpenType.DOWNLOAD:
        return 'Download';
      case OpenType.OPEN:
        return 'Öffnen';
      default:
        return ''
    }
  }

  openProject(product: Product, e: Event) {
    if (product.transitionType) {
      e.preventDefault();
      this.$emit('view-transition', product.transitionType);
      setTimeout(() => {
        this.$router.push(product.url!);
      }, 600);
    }
  }

  mounted() {

    const insertStyleSheetRule = (ruleText: string) => {
      const sheets = document.styleSheets;
      if(sheets.length === 0) {
        const style = document.createElement('style');
        style.appendChild(document.createTextNode(""));
        document.head.appendChild(style);
      }

      const sheet = sheets[sheets.length - 1];
      sheet.insertRule(ruleText, sheet.rules ? sheet.rules.length : sheet.cssRules.length);
    }

    const lists: HTMLCollectionOf<Element> = document.getElementsByClassName("tech-list");
    for(let i = 0; i < lists.length; i++) {
      const item = lists[i] as HTMLElement;
      const content = item.innerHTML;
      item.innerHTML = content + content; // double the content

      const margin = (item.children[0]! as HTMLElement).style.marginLeft === '50px' ? 50 : 25;
      const width = parseInt(item.nextElementSibling!.innerHTML) + item.children.length * margin;
      const t = width / 50;
      item.style.animation = "anim" + i +" "+ t +"s linear 1s infinite";
      insertStyleSheetRule("@keyframes anim"+i+" { 100% { transform: translateX(-"+width+"px); } }");
    }
  }
}
