






import { Component, Vue } from "vue-property-decorator";
import Imprint from "@/general/Imprint.vue";
import NotanLayout from "@/notan/NotanLayout.vue";

@Component({
  components: {
    NotanLayout,
    Imprint,
  },
})
export default class NotanImprint extends Vue {}
