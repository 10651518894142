

























































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import GeoWarLayout from "@/geowar/GeoWarLayout.vue";

@Component({
  components: {
    GeoWarLayout
  },
})
export default class GeoWarPrivacy extends Vue {}
