













import {Component, Prop, Vue} from 'vue-property-decorator';
import {ViewTransitionType} from "@/general/view-transition-type";

@Component
export default class ViewTransition extends Vue {

  @Prop({required: true})
  readonly transitionType!: ViewTransitionType;

  get backgroundColor(): string {
    switch (this.transitionType) {
      case ViewTransitionType.HOME:
        return '#01579B';
      case ViewTransitionType.NOTAN:
        return '#303030';
      case ViewTransitionType.GEOWAR:
        return '#222222';
      default:
        return ''
    }
  }

  get wrapperClass(): string {
    switch (this.transitionType) {
      case ViewTransitionType.HOME:
        return 'transition-wrapper-from-left';
      case ViewTransitionType.NOTAN:
      case ViewTransitionType.GEOWAR:
        return 'transition-wrapper-from-right';
      default:
        return ''
    }
  }

}
