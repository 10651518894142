






import { Component, Vue } from "vue-property-decorator";
import Imprint from "@/general/Imprint.vue";
import GeoWarLayout from "@/geowar/GeoWarLayout.vue";

@Component({
  components: {
    GeoWarLayout,
    Imprint,
  },
})
export default class GeoWarImprint extends Vue {}
