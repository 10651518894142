






import { Component, Vue } from "vue-property-decorator";
import ContactForm from "@/general/ContactForm.vue";
import {Module} from "@/api";
import GeoWarLayout from "@/geowar/GeoWarLayout.vue";

@Component({
  components: {
    GeoWarLayout,
    ContactForm
  },
})
export default class GeoWarContact extends Vue {

  readonly module = Module.GEOWAR;
}

