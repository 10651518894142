







import { Component, Vue } from 'vue-property-decorator';
import ViewTransition from "@/general/ViewTransition.vue";
import {ViewTransitionType} from "@/general/view-transition-type";

@Component({
  components: {
    ViewTransition
  },
})
export default class App extends Vue {

  homeVisited = false; // whether or not home ('/') has been visited (only show animation once) <-- not used anymore (always animate)
  transition: ViewTransitionType | null = null; // show view transition if not null

  visitHome() {
    this.homeVisited = true;
  }

  viewTransition(t: ViewTransitionType) {
    this.transition = t;
    setTimeout(() => {
      this.transition = null;
    }, 1100);
  }
}
