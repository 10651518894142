
































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Module, sendContactRequest} from "@/api";
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

@Component({
  components: {
    VueHcaptcha
  }
})
export default class ContactForm extends Vue {

  @Prop({required: true})
  readonly module!: Module;

  @Prop({required: true})
  readonly highlightColor!: Module;

  @Prop({required: true})
  readonly captchaTheme!: 'light' | 'dark';

  name = '';
  email = '';
  phone = '';
  content = '';
  sent = false;
  captchaToken: string | null = null;

  tokenVerified(token: string) {
    this.captchaToken = token;
  }

  sendContact() {

    if (!this.captchaToken)
      return;

    sendContactRequest({
      captcha: this.captchaToken,
      module: this.module,
      name: this.name,
      email: this.email,
      phone: this.phone,
      content: this.content
    })

    this.sent = true;
    this.captchaToken = null;
  }

}
