import {ViewTransitionType} from "@/general/view-transition-type";

interface Tech {
    name: string;
    img: string;
    url: string;
    width: number;
}

interface CoWorker {
    name: string;
    url?: string;
}

export enum OpenType {
    OPEN = -1,
    DOWNLOAD = -2
}

export interface Product {
    name: string;
    logo?: string;
    icon?: string;
    sub: string;
    badges: Array<string>;
    tech: Array<Tech>;
    coWorker: Array<CoWorker>;
    open?: OpenType;
    url?: string;
    transitionType?: ViewTransitionType;
}

export interface Library {
    name: string;
    tags: Array<string>;
    info: string;
    pub?: string;
    github?: string;
}

const techMap = {
    // languages
    "dart": {
        "name": "Dart",
        "img": "dart.png",
        "url": "https://dart.dev",
        "width": 100
    },
    "java": {
        "name": "Java",
        "img": "java.svg",
        "url": "http://java.com",
        "width": 45
    },
    "javascript": {
        "name": "JavaScript",
        "img": "javascript.svg",
        "url": "http://wikipedia.org/wiki/JavaScript",
        "width": 60
    },
    "kotlin": {
        "name": "Kotlin",
        "img": "kotlin.png",
        "url": "http://kotlinlang.org",
        "width": 120
    },
    "python":{
        "name": "Python",
        "img": "python.webp",
        "url": "https://www.python.org",
        "width": 180
    },
    "rust": {
        "name": "Rust",
        "img": "rust.png",
        "url": "https://www.rust-lang.org",
        "width": 120
    },
    "typescript": {
        "name": "Rust",
        "img": "typescript.png",
        "url": "https://www.typescriptlang.org",
        "width": 70
    },

    // frameworks
    "flutter": {
        "name": "Flutter",
        "img": "flutter.png",
        "url": "https://flutter.dev",
        "width": 130
    },
    "graphQL": {
        "name": "GraphQL",
        "img": "graphql.png",
        "url": "https://graphql.org",
        "width": 160
    },
    "libGDX": {
        "name": "LibGDX",
        "img": "libgdx.png",
        "url": "http://libgdx.badlogicgames.com",
        "width": 120
    },
    "react": {
        "name": "React",
        "img": "react.png",
        "url": "https://reactjs.org/",
        "width": 120
    },
    "socketIO": {
        "name": "Socket.IO",
        "img": "socketio.svg",
        "url": "https://socket.io",
        "width": 150
    },
    "spring": {
        "name": "Spring",
        "img": "spring.png",
        "url": "http://spring.io",
        "width": 120
    },
    "swing": {
        "name": "Swing",
        "img": "swing.png",
        "url": "http://docs.oracle.com/javase/7/docs/api/javax/swing/package-summary.html",
        "width": 160
    },
    "tailwind":{
        "name": "Tailwind CSS",
        "img": "tailwind.svg",
        "url": "https://tailwindcss.com",
        "width": 200
    },
    "vue": {
        "name": "Vue",
        "img": "vue.png",
        "url": "https://vuejs.org",
        "width": 140
    },

    // other
    "docker": {
        "name": "Docker",
        "img": "docker.png",
        "url": "https://www.docker.com",
        "width": 160
    },
    "jwt": {
        "name": "JWT",
        "img": "jwt.png",
        "url": "https://jwt.io",
        "width": 130
    },
    "mongoDB": {
        "name": "MongoDB",
        "img": "mongodb.png",
        "url": "https://www.mongodb.com",
        "width": 150
    },
    "mySQL": {
        "name": "MySQL",
        "img": "mysql.png",
        "url": "https://www.mysql.com",
        "width": 120
    },
    "nginx": {
        "name": "NGINX",
        "img": "nginx.png",
        "url": "http://www.nginx.com",
        "width": 120
    },
    "nodeJS": {
        "name": "NodeJS",
        "img": "nodejs.png",
        "url": "https://nodejs.org",
        "width": 100
    },
    "postgreSQL": {
        "name": "PostgreSQL",
        "img": "postgresql.png",
        "url": "https://www.postgresql.org/",
        "width": 100
    },
    "sqlite": {
        "name": "SQLite",
        "img": "sqlite.gif",
        "url": "https://www.sqlite.org",
        "width": 100
    },
    "tomcat": {
        "name": "Tomcat",
        "img": "tomcat.png",
        "url": "http://tomcat.apache.org",
        "width": 120
    }
}

const workerMap = {
    "alexander": {
        "name": "Alexander Babel",
        "url": "https://alexbabel.com/"
    },
    "jonas": {
        "name": "Jonas Embach",
        "url": "https://bit-production.com"
    },
    "aleksa": {
        "name": "Aleksa Perovic"
    },
    "dominik": {
        "name": "Dominik Weiß"
    },
    niels: {
        "name": "Niels Sedat"
    },
};

const productMap = {
    doppelSpalt: {
        name: 'Doppelspalt Simulation',
        logo: 'doppelspalt.png',
        sub: 'Projekt im Physikunterricht',
        badges: ['Desktop'],
        tech: [techMap.java, techMap.swing, techMap.java, techMap.swing], // duplicate
        coWorker: [],
        open: OpenType.DOWNLOAD,
        url: '/static/home/doppelspalt.jar'
    },
    finvote: {
        name: 'FinVote',
        logo: 'finvote.png',
        sub: 'Votierungssystem für Hochschulen',
        badges: ['Android', 'iOS', 'Web'],
        tech: [techMap.kotlin, techMap.spring, techMap.postgreSQL, techMap.jwt, techMap.graphQL, techMap.javascript, techMap.react],
        coWorker: [workerMap.alexander, workerMap.jonas],
        open: OpenType.OPEN,
        url: 'https://finvote.de'
    },
    geowar: {
        name: 'GeoWar',
        logo: 'geowar.png',
        sub: 'Multiplayer Echtzeit-Strategiespiel',
        badges: ['Android', 'iOS', 'Web'],
        tech: [techMap.java, techMap.libGDX, techMap.nodeJS, techMap.mongoDB, techMap.socketIO],
        coWorker: [workerMap.alexander],
        open: OpenType.OPEN,
        url: '/geowar',
        transitionType: ViewTransitionType.GEOWAR
    },
    homepage: {
        name: 'tienisto.com',
        icon: 'mdi-laptop',
        sub: 'Aktuelle Webseite',
        badges: ['Web'],
        tech: [techMap.vue, techMap.typescript, techMap.rust, techMap.docker],
        coWorker: []
    },
    localSend: {
        name: 'LocalSend',
        logo: 'localsend.png',
        sub: 'Senden von Dateien über WLAN',
        badges: ['Android', 'iOS', 'Windows', 'MacOS', 'Linux'],
        tech: [techMap.flutter, techMap.dart, techMap.flutter, techMap.dart], // duplicate
        coWorker: [],
        open: OpenType.OPEN,
        url: 'https://localsend.org'
    },
    notan: {
        name: 'Notan',
        logo: 'notan.png',
        sub: 'Notenrechner mit Abiturfunktion',
        badges: ['Android', 'iOS'],
        tech: [techMap.flutter, techMap.dart, techMap.sqlite, techMap.flutter, techMap.dart, techMap.sqlite], // duplicate
        coWorker: [],
        open: OpenType.OPEN,
        url: '/notan',
        transitionType: ViewTransitionType.NOTAN
    },
    ovgu: {
        name: 'Welcome to OVGU',
        logo: 'ikus.png',
        sub: 'Uni-App',
        badges: ['Android', 'iOS', 'Web'],
        tech: [techMap.flutter, techMap.dart, techMap.spring, techMap.kotlin, techMap.postgreSQL, techMap.jwt, techMap.vue, techMap.javascript],
        coWorker: [],
        open: OpenType.OPEN,
        url: 'https://github.com/Tienisto/ikus-app'
    },
    sharkbench: {
      name: 'Sharkbench',
      logo: 'sharkbench.webp',
      sub: 'Benchmarks für Programmiersprachen',
      badges: ['Web'],
      tech: [techMap.vue, techMap.typescript, techMap.rust, techMap.docker],
      coWorker: [],
      open: OpenType.OPEN,
      url: 'https://sharkbench.dev'
    },
    sportbench: {
      name: 'Sportbench',
      logo: 'sportbench.webp',
      sub: 'Kompetitive Sport-App',
      badges: ['Android', 'iOS', 'Web'],
      tech: [techMap.flutter, techMap.dart, techMap.python, techMap.postgreSQL, techMap.rust, techMap.docker, techMap.vue, techMap.tailwind, techMap.typescript],
      coWorker: [workerMap.niels],
      open: OpenType.OPEN,
      url: 'https://sportben.ch'
    },
    stephaneum: {
        name: 'Stephaneum',
        logo: 'stephaneum.png',
        sub: 'Webplattform des Gymnasiums',
        badges: ['Web'],
        tech: [techMap.vue, techMap.kotlin, techMap.spring, techMap.mySQL, techMap.nginx],
        coWorker: [],
        open: OpenType.OPEN,
        url: 'https://stephaneum.de'
    },
    tictactoe: {
        name: 'Tic Tac Toe',
        logo: 'tictactoe.png',
        sub: 'Strategiespiel',
        badges: ['Desktop'],
        tech: [techMap.java, techMap.swing, techMap.java, techMap.swing], // duplicate
        coWorker: [workerMap.dominik, workerMap.aleksa],
        open: OpenType.DOWNLOAD,
        url: '/static/home/tictactoe.jar'
    }
}

const libraryMap = {
    slang: {
        name: 'Slang',
        tags: ['Flutter'],
        info: 'Eine Flutter-Bibliothek, die es einfach macht, Übersetzungen zu implementieren.',
        pub: 'slang',
        github: 'slang-i18n/slang',
    },
    refena: {
        name: 'Refena',
        tags: ['Flutter'],
        info: 'Eine State-Management-Bibliothek für Flutter.',
        pub: 'refena',
        github: 'refena/refena',
    },
};

export const productList: Array<Product> = [
    productMap.localSend,
    productMap.sharkbench,
    productMap.sportbench,
    productMap.notan,
    productMap.ovgu,
    productMap.finvote,
    productMap.stephaneum,
    productMap.geowar,
    productMap.tictactoe,
    productMap.homepage,
    productMap.doppelSpalt
];

export const libraryList: Array<Library> = [
    libraryMap.slang,
    libraryMap.refena,
];
