




import { Component, Vue } from 'vue-property-decorator';
import GenericFooter from "@/general/GenericFooter.vue";

@Component({
  components: {
    GenericFooter
  },
})
export default class HomeFooter extends Vue {
}
